import { AppProps } from "next/app";
import { NextPage } from "next";
import React from "react";
import Theme from "@/theme/index";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import { Provider } from "react-redux";
import { store } from "@/redux";
import Script from "next/script";
import "@/theme/global.css";
import "@/style/index.scss";

export type ComponentExpansion = NextPage & {
  getLayout?: (page: React.ReactNode) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  Component: ComponentExpansion;
}

export default function App(props: MyAppProps) {
  const { Component, pageProps } = props;
  const { getLayout } = Component;
  return (
    <>
      <title>Mempool Portfolio: Track & Manage BTC Assets</title>
      <AppCacheProvider {...props}>
        <Provider store={store}>
          {getLayout ? (
            getLayout(<Component {...pageProps} />)
          ) : (
            <Component {...pageProps} />
          )}
        </Provider>
      </AppCacheProvider>

      {/* 多钱包专用 */}
      <Script strategy="lazyOnload" src="/iconfont/multipleWallet.js" />
      {/* dotswap图标 */}
      <Script strategy="lazyOnload" src="/iconfont/dotswap.js" />
      {/* logo */}
      <Script strategy="lazyOnload" src="/iconfont/logo.js" />

      <Script strategy="lazyOnload" id="gtag">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6K5Y7116KL');
        `}
      </Script>
    </>
  );
}
