
import { createSlice } from '@reduxjs/toolkit';
// import Big from 'big.js';
interface ConfigState {
  usdRate: number; // btc 对 usd 汇率
}

const initialState: ConfigState = {
  usdRate: 0,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setRate: (state, action) => {
      state.usdRate = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const {
  setRate,
} = configSlice.actions;

export default configSlice.reducer;
