import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "@/redux/slices/wallet";
import checkFollowSliceReducer from "@/redux/slices/follow";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import accountSliceReducer from "@/redux/slices/account";
import configSliceReducer from "@/redux/slices/config"

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    checkFollow: checkFollowSliceReducer,
    account: accountSliceReducer,
    config: configSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
