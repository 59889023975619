import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletAccountGetAccountItem } from "@/types/wallet_manager";

interface AccountState {
  account_id: string;
  address_list: WalletAccountGetAccountItem[];
}

const initialState: AccountState = {
  account_id: "",
  address_list: [],
};

export const AccountSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setAccountInfo(state, action: { payload: AccountState }) {
      const { account_id = "", address_list = [] } = action.payload;
      state.account_id = account_id;
      state.address_list = address_list;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setAccountInfo } = AccountSlice.actions;

export default AccountSlice.reducer;
