import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WalletState {
  isConnect: boolean;
  address: string;
  btcAddress: string; // 所有钱包的 btc address 都用这个，每次接入新钱包记得赋值
  walletName: string;
  accounts: string[];
  network: string;
  publicKey: string;
  btcPublicKey: string; // 所有钱包的 btc publicKey 都用这个，每次接入新钱包记得赋值
  balance: {
    confirmed: number;
    unconfirmed: number;
    total: number;
  };
  apiBalance: {
    btc_balance: number;
    inscription_balance: number;
    total_balance: number;
  };
  openConnectDialog: boolean;
  initWallet: boolean; // 用于判断是否已经初始化钱包
  privateKey?: string;
}

const initialState: WalletState = {
  isConnect: false,
  address: "",
  btcAddress: "",
  walletName: "",
  accounts: [],
  network: "",
  publicKey: "",
  btcPublicKey: "",
  balance: {
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  },
  apiBalance: {
    btc_balance: 0,
    inscription_balance: 0,
    total_balance: 0,
  },
  openConnectDialog: false,
  initWallet: false,
  privateKey: "",
};

export const walletSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setIsConnect(state, action) {
      const { isConnect, walletName } = action.payload;
      state.isConnect = isConnect;
      state.walletName = isConnect ? walletName : "";
      state.initWallet = true;
      if (typeof window !== "undefined") {
        window.localStorage.setItem("wallet", isConnect ? walletName : "");
      }
    },
    setAddress(state, action) {
      state.address = action.payload;
    },
    setBTCAddress(state, action) {
      state.btcAddress = action.payload;
    },
    setNetwork(state, action) {
      state.network = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setPublicKey(state, action) {
      state.publicKey = action.payload;
    },
    setBTCPublicKey(state, action) {
      state.btcPublicKey = action.payload;
    },
    setBalance(state, action) {
      state.balance = action.payload;
    },
    setApiBalance(state, action) {
      state.apiBalance = action.payload;
    },
    clearBalance(state) {
      state.balance = {
        confirmed: 0,
        unconfirmed: 0,
        total: 0,
      };
      state.apiBalance = {
        btc_balance: 0,
        inscription_balance: 0,
        total_balance: 0,
      };
    },
    setOpenConnectDialog(state, action) {
      state.openConnectDialog = action.payload;
    },
    setAllWalletInfo(state, action: PayloadAction<WalletState>) {
      const handleState: any = state;
      const content: any = action.payload;
      Object.keys(action.payload).forEach((key: any) => {
        handleState[key] = content[key];
      });
    },
    setPrivateKey(state, action) {
      state.privateKey = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const {
  setOpenConnectDialog,
  setIsConnect,
  setAddress,
  setBTCAddress,
  setNetwork,
  setAccounts,
  setPublicKey,
  setBTCPublicKey,
  setBalance,
  clearBalance,
  setAllWalletInfo,
  setPrivateKey,
} = walletSlice.actions;

export default walletSlice.reducer;
