import { FollowItem } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface CartState {
  hasCheckList: FollowItem[]; // 已选择列表（对象）
  checkedItems: string[]; // 选中的id(唯一标识,即给后端的值)
  total: number; // 总数
}

const initialState: CartState = {
  hasCheckList: [],
  checkedItems: [],
  total: 0,
};
export const uniqueId = "follow_address";
export const checkFollowSlice = createSlice({
  name: "follow",
  initialState,
  reducers: {
    // 处理添加
    addToList(state, action) {
      const item = action.payload;
      // 检查是否已存在
      const existItem = state.hasCheckList.find(
        (x) => x[uniqueId] === item[uniqueId],
      );

      // 记录被选中的id
      if (!state.checkedItems.includes(item[uniqueId])) {
        state.checkedItems.push(item[uniqueId]);
      }

      if (!existItem) {
        //  不存在,直接添加到列表中
        state.hasCheckList.push({
          ...item,
        });
      } else {
      }

      // 重新计算总数
      state.total = state.hasCheckList.length;
    },
    // 处理删除逻辑
    removeFromList(state, action) {
      const id = action.payload;
      // 1. 过滤掉要删除的选项
      state.hasCheckList = state.hasCheckList.filter(
        (item) => item[uniqueId] !== id,
      );
      // 移除时,同时更新选中项
      state.checkedItems = state.checkedItems.filter((itemId) => itemId !== id);
      state.total = state.hasCheckList.length;
    },

    // 更新
    updatedList(state, action) {
      const newList: FollowItem[] = action.payload;
      // 记录被选中的商品id
      state.checkedItems = newList.map((item) => item[uniqueId]);
      // 更新商品
      state.hasCheckList = newList;
      // 重新计算总数
      state.total = newList.length;
    },
    // 清空
    clearList(state) {
      state.hasCheckList = [];
      state.total = 0;
      state.checkedItems = [];
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { addToList, removeFromList, updatedList, clearList } =
  checkFollowSlice.actions;

export default checkFollowSlice.reducer;
